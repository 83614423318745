type Translation = {
	formatDate: (string) => string;
	navigation: {
		activateDarkMode: string;
		activateLightMode: string;
		copyAddress: string;
		addressCopied: string;
		logoLinkText: string;
		next: string;
		prev: string;
		of: string;
		footerText: (string) => string;
	},
	subscription: {
		heading: string;
		text: string;
		placeholder: string;
		subscribe: string;
	};
	articles: {
		timeToRead: string;
		showInTile: string;
		showInRow: string;
		toggleDarkAndLightMode: string;
		shareText: string;
		shareButtonText: string;
	};
	localeString: string;
};

export const isPersian = process.env.IS_PERSIAN || true;

const translations: Translation = {
	localeString: isPersian ? 'fa-IR' : 'en-US',
	formatDate: (date: string) => new Date(date).toLocaleDateString(isPersian ? 'fa-IR' : 'en-US', {
		calendar: "persian",
		year: "numeric",
		month: "long",
		day: "2-digit",
	}),
	navigation: {
		activateDarkMode: isPersian ? 'حالت تاریک رو فعال کنید' : 'Activate dark mode',
		activateLightMode: isPersian ? 'حالت روشن رو فعال کنید' : 'Activate light mode',
		addressCopied: isPersian ? 'کپی شد!' : 'Copied!',
		copyAddress: isPersian ? 'آدرس صفحه رو کپی کنید' : 'Copy URL to clipboard',
		logoLinkText: isPersian ? 'برگردید به صفحه‌ی اصلی' : 'Navigate back to the homepage',
		next: isPersian ? 'بعدی' : 'Next',
		prev: isPersian ? 'قبلی' : 'Previous',
		of: isPersian ? 'از' : 'of',
		footerText: (date: string) => isPersian ? `کپی‌رایت ${date}، حقوق معنوی محفوظ هست، ولی میتونید با ذکر منبع مطالب رو منتشر کنید.` : `Copyright ${date}, All rights reserved`
	},
	subscription: {
		heading: isPersian ? 'میخوای همیشه بروز باشی؟' : 'Join my email list and get notified about new content',
		text: isPersian ? 'کافیه خیلی ساده ایمیلت رو اینجا بدی و من به محض انتشار یه پست جدید بهت خبر میدم. راستی، ایمیل رو با کسی به اشتراک نمیذارم و قرار نیست اسپم دریافت کنی.' : 'Be the first to receive our latest content with the ability to opt-out at anytime. We promise to not spam your inbox or share your email with any third parties.',
		placeholder: 'your@email.com',
		subscribe: isPersian ? 'عضو میشم!' : 'Subscribe',
	},
	articles: {
		timeToRead: isPersian ? 'دقیقه مطالعه' : 'min to read',
		showInTile: isPersian ? 'نمایش شبکه‌ای' : 'Show in tile grid',
		showInRow: isPersian ? 'نمایش خطی' : 'Show in row grid',
		toggleDarkAndLightMode: isPersian ? 'تغییر حالت بین تاریک و روشن' : 'Toggle dark and light mode',
		shareButtonText: isPersian ? 'متن انتخاب شده رو کپی کنید' : 'Copy selected text',
		shareText: isPersian ? 'به اشتراک بذارید' : 'Share',
	}
};

export default translations;