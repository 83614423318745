export default {
	spacing0: 0,
	spacing1: '0.25rem',
	spacing2: '0.5rem',
	spacing3: '0.75rem',
	spacing4: '1rem',		// 2px
	spacing5: '1.25rem',
	spacing6: '1.5rem',
	spacing8: '2rem',
	spacing9: '2.1rem',
	spacing10: '2.5rem',
	spacing12: '3rem',
	spacing16: '4rem',
	spacing20: '5rem',
	spacing24: '6rem',
	spacing32: '8rem',

	maxWidthNone: "none",
	maxWidthXXS: '16rem',
	maxWidthXS: '20rem',
	maxWidthSM: '24rem',
	maxWidthMD: '28rem',
	maxWidthLG: '32rem',
	maxWidthXL: '36rem',
	maxWidth2XL: '42rem',
	maxWidth3XL: '48rem',
	maxWidth4XL: '56rem',
	maxWidth5XL: '62rem',
	maxWidthFull: '100%',
	maxWidthFullView: '100vw',

	heightNone: "none",
	heightXXS: '10rem',
	heightXS: '15rem',
	heightSM: '20rem',
	heightMD: '25rem',
	heightLG: '45rem',
	heightXL: '50rem',
	height2XL: '70rem',
	height3XL: '75rem',
	height4XL: '80rem',
	height5XL: '100rem',

	lineHeightNone: 1,
	lineHeightTight: 1.1,
	lineHeightNormal: 1.65,
	lineHeightRelaxed: 1.825,

	fontSize0: '0.833rem',
	fontSize1: '1rem',
	fontSize2: '1.2rem',
	fontSize3: '1.44rem',
	fontSize4: '1.728rem',
	fontSize5: '2.074rem',
	fontSize6: '2.488rem',
	fontSize7: '2.986rem',

	borderRadiusNone: '0',
	borderRadiusLight: '2px',
	borderRadiusNormal: '4px',
	borderRadiusHeavy: '8px',
	borderRadiusHalf: '50%',
}