import merge from 'lodash/merge';

import colors from './colors';
import tags from './tags';
import sizing from './sizing';
import translations, { isPersian } from './translations';

const breakpoints = [
  ['phone_small', 320],
  ['phone', 376],
  ['phablet', 540],
  ['tablet', 735],
  ['desktop', 1070],
  ['desktop_medium', 1280],
  ['desktop_large', 1440],
];

const fonts = {
  serif: "'Estedad', serif;",
  sansSerif:
    "'Estedad', sans-serif;",
  monospace: `"Operator Mono", Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
};

const colorModeTransition =
  'background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)';

const shadows = {
  default: `rgba(0, 0, 0, 0.1) -4px 9px 25px -6px`,
  hovered: `rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px`,
};

const merged: {
  initialColorMode: 'light' | 'dark';
  useCustomProperties: boolean;
  colorModeTransition: string;
  colors: typeof colors;
  shadows: typeof shadows;
  fonts: typeof fonts;
  breakpoints: typeof breakpoints;
  tags: typeof tags;
  translations: typeof translations;
  sizing: typeof sizing;
  isRtl: boolean;
} = merge({
  initialColorMode: 'light',
  useCustomProperties: true,
  colorModeTransition,
  colors,
  shadows,
  fonts,
  breakpoints,
  tags,
  translations,
  sizing,
  isRtl: isPersian
});

export type PropType = {
  theme: typeof merged;
};

export default merged;